<template>
  <div
    ref="header"
    class="manager-table-header__mobile header-container"
    :class="{ 'header-container--sticky': isHeaderSticky }"
  >
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      "
    >
      <el-input
        v-model="search"
        placeholder="Введите номер авто"
        clearable
        @clear="handleEmitFilterParams"
        @keyup.enter.native="handleEmitFilterParams"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          style="
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          @click="handleEmitFilterParams"
        />
      </el-input>
      <el-badge :value="filterCount" class="item" type="primary">
        <el-button
          type="warning"
          style="
            margin-left: 16px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          @click="visibleFilterModal = true"
        >
          <i class="el-icon-s-operation icon-filter" />
        </el-button>
      </el-badge>
    </div>
    <div style="width: 100%" class="df jc-sb ai-c m-t-24">
      <text-select @handleChoseSort="handleEmitSort" />
      <div v-if="!!totalCount" class="df jc-sb ai-c">
        <p
          style="
            color: #606266;
            font-size: 12px;
            font-family: Roboto, sans-serif;
          "
        >
          Всего автомобилей
        </p>
        <div
          style="
            margin-left: 6px;
            display: flex;
            border: 1px solid #e4e7ed;

            padding: 2px 6px;
            background: white;
            border-radius: 8px;
          "
        >
          <span style="color: #303133; font-size: 12px; font-weight: 500">
            {{ totalCount }}
          </span>
        </div>
      </div>
    </div>
    <modal-fullscreen-mobile
      :statuses-list="statusesList"
      :exporters-list="exportersList"
      :cultures-list="cultureList"
      :visible="visibleFilterModal"
      @close="visibleFilterModal = false"
      @handleEmitFilters="handleAddFilters"
    />
  </div>
</template>

<script>
import { debounce } from '@/core'
import ModalFullscreenMobile from '@/views/manager/components/mobile/components/modal/ModalFullscreenMobile'
import TextSelect from '@/views/manager/components/mobile/TextSelect'

export default {
  name: 'ManagerTableHeaderMobile',
  components: { TextSelect, ModalFullscreenMobile },
  props: {
    filterCount: { type: Number, default: 0 },
    cultureList: { type: Array, default: () => [] },
    statusesList: { type: Array, default: () => [] },
    exportersList: { type: Array, default: () => [] },
    totalCount: { type: Number, default: 0 },
  },
  data() {
    return {
      visibleFilterModal: false,
      scrollY: null,
      headerTop: 0,
      isHeaderSticky: false,
      search: '',
      culture: null,
      exporter: null,
      status: null,
      time_from: null,
      time_to: null,
    }
  },
  watch: {
    scrollY(newValue) {
      if (newValue + 17 > this.headerTop) {
        this.isHeaderSticky = true
      } else {
        this.isHeaderSticky = false
      }
    },
  },
  mounted() {
    window.addEventListener('load', () => {
      window.addEventListener('scroll', () => {
        this.scrollY = Math.round(window.scrollY)
      })
      this.headerTop = this.$refs.header.getBoundingClientRect().top
    })
  },
  methods: {
    handleAddFilters(filters, count) {
      this.$emit(
        'emitFilterParams',
        {
          search: this.search,
          ...filters,
        },
        count,
      )
    },
    handleEmitSort(sort) {
      this.$emit('emitSort', sort)
    },
    debounceEmitFilterParams() {
      debounce(this.handleEmitFilterParams, 1000)
    },
    handleEmitFilterParams() {
      this.$emit('emitFilterParams', {
        search: this.search,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.icon-filter
  font-size: 24px!important
  transform: rotate(90deg)
.manager-table-header__mobile
  padding: 20px 0px
  background: transparent
  border-radius: 4px 4px 0 0

.header-container
  &--sticky
    border-bottom: 1px solid #EBEEF5
    padding-right: 15px
    padding-left: 15px
    margin-right: -15px
    margin-left: -15px
    background: white
    position: sticky
    top: 42px
    z-index: 99
</style>
